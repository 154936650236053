import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { TicketDataService } from '../ticket-data.service';
import { Ticket } from '../ticketData';
import { BrandDataService } from '../brand-data.service';
import { Brand } from '../brandData';
import {ErrorStateMatcher} from '@angular/material/core';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'vogel-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  providers: [TicketDataService, BrandDataService]
})

export class ContentComponent implements OnInit {
  year: number = new Date().getFullYear();
  // formGroup with formControls
  ticketDataForm = new UntypedFormGroup({
    titleTypeControl: new UntypedFormControl(''),
    requestTypeControl: new UntypedFormControl(''),
    topicZUATypeControl: new UntypedFormControl(''),
    topicTypeControl: new UntypedFormControl(''),
    detailsFormControl: new UntypedFormControl('', Validators.required),
    emailFormControl: new UntypedFormControl('', [Validators.required, Validators.email]),
    eventFormControl: new UntypedFormControl(''),
    genderFormControl: new UntypedFormControl(''),
    fullNameFormControl: new UntypedFormControl(''),
    telephoneFormControl: new UntypedFormControl(''),
    brandFormControl: new UntypedFormControl(''),
    dataAgreedFormControl: new UntypedFormControl('', Validators.required),
    firmaFormControl: new UntypedFormControl(""),
    addressFormControl: new UntypedFormControl(""),
    kundennummerFormControl: new UntypedFormControl(""),
    abonummerFormControl: new UntypedFormControl(""),
    oldAddressFormControl: new UntypedFormControl(""),
    newAddressFormControl: new UntypedFormControl(""),
    ausgabenNrFormControl: new UntypedFormControl(""),
    empfaengerFormControl: new UntypedFormControl(""),
    rechnungMahnungInkassoFormControl: new UntypedFormControl(""),
    strasseFormControl: new UntypedFormControl(""),
    plzFormControl: new UntypedFormControl(""),
    stadtFormControl: new UntypedFormControl(""),
    landFormControl: new UntypedFormControl("")
  });
  param: { lang: string; brand: 'vcg'; requestType: string; info: any; topic: string; };
  title = 'Customer Support Center';
  toolbar_img = 'https://cdn4.vogel.de/logos/1009/logo.svg';
  ticketDataValidation: boolean = false;
  dataPolicyValidation: boolean = true;
  showProgress = false;
  isWIN = false;
  isIWW = false;
  formSelectedBrand;
  showZUA = true;
  brandData: Brand;
  ticketData: Ticket;
  ticketId;
  hostname;
  predefinedRequestType;
  brands = [];
  choosenBrand = { 
    value: null,
    viewValue: null,
    isDigitalCooperation: null,
    isVIT: null,
    hasPrint: null,
    datam: null,
    leserservice_link: null,
    brandID: null,
    portal_url: null };

  details = [
    { value: 'zua', viewValue: 'ZUA' },
    { value: 'run', viewValue: 'RUN' },
    { value: 'ts', viewValue: 'TS' },
    { value: 'madst', viewValue: 'MADST' },
    { value: 'madr', viewValue: 'MADR' },
    { value: 'mae', viewValue: 'MAE' },
    { value: 'sa', viewValue: 'SA' }
  ];

  detailsIWW = [
    { value: 'a', viewValue: 'A' },
    { value: 'pa', viewValue: 'PA' },
    { value: 'eus', viewValue: 'EUS' },
    { value: 'webiww', viewValue: 'WEBIWW' },
    { value: 'wlk', viewValue: 'WLK' },
    { value: 'd', viewValue: 'D' },
    { value: 'sf', viewValue: 'SF' }
  ];

  topicZUA = [
    { value: 'aah', viewValue: 'AAH' },
    { value: 'a', viewValue: 'A' },
    { value: 'ea', viewValue: 'EA' },
    { value: 'fek', viewValue: 'FEK' }
  ];

  topicA = [
    { value: 'fa', viewValue: 'FA' },
    { value: 'ka', viewValue: 'KA' },
    { value: 'n', viewValue: 'N' },
    { value: 'a', viewValue: 'A' },
    { value: 'frmg', viewValue: 'FRMG' },
    { value: 'fb', viewValue: 'FB' }
  ];

  topicPA = [
    { value: 'wp', viewValue: 'WP' },
    { value: 'ft', viewValue: 'FT' }
  ];

  topicD = [
    { value: 'ww', viewValue: 'WW' },
    { value: 'ausk', viewValue: 'AUSK' }
  ];


  constructor(
    private dataService: TicketDataService,
    private brandService: BrandDataService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private _snackBar: MatSnackBar) { }
  ngOnInit() {
    this.hostname = window.location.hostname;
    this.predefinedRequestType = this.activatedRoute.snapshot.params['requestType'];

    this.param = {
      lang: this.activatedRoute.snapshot.params['lang'],
      brand: this.activatedRoute.snapshot.params['brand'],
      requestType: this.activatedRoute.snapshot.params['requestType'],
      topic: this.activatedRoute.snapshot.params['topic'],
      info: this.activatedRoute.snapshot.queryParamMap.get('info')
    };

    if (this.activatedRoute.snapshot.params['brand'] == undefined) {
      this.choosenBrand.leserservice_link = 'https://www.datam-services.de/leserservice/';
    };
    // get all brands and push to array
    this.brandService.getAllBrands().subscribe(response => {
      let res = response['data'];
      if (response['status'] == 'success'){
        res.forEach(item => {
          if ( item.list_on_contact_site == 1 ) {
            this.brands.push({ 
              value: item.brandShortcut,
              viewValue: item.brandFullName,
              isDigitalCooperation: item.isDigitalCooperation,
              isVIT: item.isVIT,
              hasPrint: item.hasPrint,
              datam: item.datam,
              leserservice_link: 'https://www.datam-services.de/leserservice/' + item.leserservice_link,
              brandID: item.brandID,
              portal_url: item.portal_url });
          }
        });
      } else{
        this.brands.push({
          value: 'vcg',
          viewValue: 'Vogel Communications Group',
          isDigitalCooperation: 0,
          isVIT: 0,
          hasPrint: 1,
          datam: 0,
          leserservice_link: 'https://www.datam-services.de/leserservice/',
          branddID: 1009,
          portal_url: 'https://vogel.de/' });
      };
      if (this.activatedRoute.snapshot.params['brand'] != undefined) {
        this.brands.forEach(item => {
          if (item.value == this.activatedRoute.snapshot.params['brand']) {
            this.choosenBrand = {
              value: item.value,
              viewValue: item.viewValue,
              isDigitalCooperation: item.isDigitalCooperation,
              isVIT: item.isVIT,
              hasPrint: item.hasPrint,
              datam: item.datam,
              leserservice_link: item.leserservice_link,
              brandID: item.brandID,
              portal_url: item.portal_url
            };
            this.toolbar_img = this.choosenBrand.isDigitalCooperation === 2 ? 'https://cdn2.vogel.de/applications/' + this.choosenBrand.brandID + '/img/logo.svg' : 'https://cdn4.vogel.de/logos/' + this.choosenBrand.brandID + '/logo.svg';
          }
        });
        if (this.choosenBrand.hasPrint == '0') {
          this.details.splice(0, 1);
        };
        if (this.choosenBrand.brandID === 9995 || this.choosenBrand.brandID === 87 || this.choosenBrand.brandID === 88 || this.choosenBrand.brandID === 89 || this.choosenBrand.brandID === 90 || this.choosenBrand.brandID === 91 || this.choosenBrand.brandID === 92 || this.choosenBrand.brandID === 93 || this.choosenBrand.brandID === 94 || this.choosenBrand.brandID === 95) {
          this.isWIN = true;
          this.toolbar_img = './../../assets/img/win-verlag.png';
        }
      } else if (this.hostname.includes('win-verlag')) {
        this.isWIN = true;
        this.toolbar_img = './../../assets/img/win-verlag.png';
      } else if (this.hostname.includes('iww')) {
        this.isIWW = true;
        this.toolbar_img = 'https://www.iww.de/img/logo_iww.svg'
      }
    });
  }

  // if form submit
  submitTicketData(ticketData: any): void {
    if (this.ticketDataForm.valid) {
      window.scroll(0, 0);
      this.showProgress = true;
      ticketData = [{
        requestType: this.ticketDataForm.get('requestTypeControl').value,
        title: this.ticketDataForm.get('titleTypeControl').value,
        gender: this.ticketDataForm.get('genderFormControl').value,
        details: this.ticketDataForm.get('detailsFormControl').value,
        email: this.ticketDataForm.get('emailFormControl').value,
        event: this.ticketDataForm.get('eventFormControl').value,
        fullName: this.ticketDataForm.get('fullNameFormControl').value,
        dataAgreement: this.ticketDataForm.get('dataAgreedFormControl').value,
        telephone: this.ticketDataForm.get('telephoneFormControl').value,
        topicZUA: this.ticketDataForm.get('topicZUATypeControl').value,
        firma: this.ticketDataForm.get('firmaFormControl').value,
        abonummer: this.ticketDataForm.get('abonummerFormControl').value,
        empfaenger: this.ticketDataForm.get('empfaengerFormControl').value,
        straße: this.ticketDataForm.get('strasseFormControl').value,
        plz: this.ticketDataForm.get('plzFormControl').value,
        stadt: this.ticketDataForm.get('stadtFormControl').value,
        land: this.ticketDataForm.get('landFormControl').value,
        brand: this.choosenBrand.viewValue,
        lang: this.param.lang }];
      this.dataService.addTicketData(ticketData).subscribe(res => {
        setTimeout(() => {
          if (res['status'] == 'success') {
            this.ticketId = res['message'];
            this.ticketDataValidation = true;
            this.ticketDataForm.reset();
            this.showProgress = false;
          };
        }, 1100);
      });
    } else {
      if (this.ticketDataForm.get('dataAgreedFormControl').value == false) {
        this.dataPolicyValidation = false;
      }
      if (this.ticketDataValidation == false) {
        this._snackBar.openFromComponent(SnackBarInfo, {
          duration: 2000
        });
      };
    };
  };
  // if privacy policy checkbox state change
  dataCheckBoxChange() {
    if (this.ticketDataForm.get('dataAgreedFormControl').value == false) {
      this.dataPolicyValidation = false;
    } else {
      this.dataPolicyValidation = true;
      this._snackBar.dismiss();
    }
  }
  requestTypeChanged(x: string) {
    this.param.topic = '';
  }
  evaluateBrand(brand) {
    if (brand != undefined) {
      this.formSelectedBrand = brand;
      // get selected brand
      this.brands.forEach(item => {
        if (item.value == brand) {
          this.choosenBrand = {
            value: item.value,
            viewValue: item.viewValue,
            isDigitalCooperation: item.isDigitalCooperation,
            isVIT: item.isVIT,
            hasPrint: item.hasPrint,
            datam: item.datam,
            leserservice_link: item.leserservice_link,
            brandID: item.brandID,
            portal_url: item.portal_url
          };
        }
      });
      // remove or add option zua to array
      if (this.showZUA == true) {
        if (this.choosenBrand.hasPrint == '0') {
          this.details.splice(0, 1);
          this.showZUA = false;
        }
      } else {
        if (this.choosenBrand.hasPrint == '1') {
          this.details.unshift({ value: 'zua', viewValue: 'ZUA' });
          this.showZUA = true;
        }
      }
    }
    this.toolbar_img = this.choosenBrand.brandID === 9996 ? './../../assets/img/vogel-fachbuch.png' : (this.choosenBrand.brandID === 9995 || this.hostname.includes('win-verlag')) ? './../../assets/img/win-verlag.png' : this.choosenBrand.isDigitalCooperation == 2 ? 'https://cdn2.vogel.de/applications/' + this.choosenBrand.brandID + '/img/logo.svg' : 'https://cdn4.vogel.de/logos/' + this.choosenBrand.brandID + '/logo.svg';
    if (this.hostname.includes('win-verlag') || this.choosenBrand.brandID === 9995 || this.choosenBrand.brandID === 87 || this.choosenBrand.brandID === 88 || this.choosenBrand.brandID === 89 || this.choosenBrand.brandID === 90 || this.choosenBrand.brandID === 91 || this.choosenBrand.brandID === 92 || this.choosenBrand.brandID === 93 || this.choosenBrand.brandID === 94 || this.choosenBrand.brandID === 95) {
      this.isWIN = true;
    }
    else {
      this.isWIN = false;
    }
  }
  openDialog() {
    //const dialogRef = this.dialog.open(DialogDataPolicy);
      if (this.hostname.includes('win-verlag') || this.choosenBrand.brandID === 9995 || this.choosenBrand.brandID === 86 || this.choosenBrand.brandID === 87 || this.choosenBrand.brandID === 88 || this.choosenBrand.brandID === 89 || this.choosenBrand.brandID === 90 || this.choosenBrand.brandID === 91 || this.choosenBrand.brandID === 92 || this.choosenBrand.brandID === 93 || this.choosenBrand.brandID === 94 || this.choosenBrand.brandID === 95) {
        this.dialog.open(DialogDataPolicyWIN);
      } else if (this.hostname.includes('iww')) {
        this.dialog.open(DialogDataPolicyIWW);
      } else {
        this.dialog.open(DialogDataPolicy);
      }
    /*dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });*/
  }
}
// data policy dialog
@Component({
  selector: 'data-policy-dialog',
  templateUrl: './../data-policy-dialog.html'
})
export class DialogDataPolicy { }
// win-verlag data policy dialog
@Component({
  selector: 'data-policy-dialog-win',
  templateUrl: './../data-policy-dialog_win.html'
})
export class DialogDataPolicyWIN { }
// iww data policy dialog
@Component({
  selector: 'data-policy-dialog-iww',
  templateUrl: './../data-policy-dialog_iww.html'
})
export class DialogDataPolicyIWW { }
// snack bar
@Component({
  selector: 'snack-bar-info',
  templateUrl: './../snack-bar-info.html'
})
export class SnackBarInfo { }